import { Cookies } from 'react-cookie-consent';

const isBrowser =  typeof window !== 'undefined';

export const goBack = () => {
  if (isBrowser) {
    Cookies.set("elapseit_language", getLanguageFromUrl(document.referrer));
    history.back();
  }
}

export const getLanguageFromUrl = (location) => {
  if (isBrowser) {
    if (location.indexOf("/de/") > -1) {
      return 'de';
    } else if (location.indexOf("/fr/") > -1) {
      return 'fr';
    } else if (location.indexOf("/it/") > -1) {
      return 'it';
    } if (location.indexOf("/es/") > -1) {
      return 'es';
    } else if (location.indexOf("/es/") > -1) {
      return 'en';
    } else {
      return 'ro';
    }
  }
}
