import React from 'react';
import SiteLayout from '../../components/layout/SiteLayout';
import { goBack } from '../../utils/functions';
import loadable from '@loadable/component';
const RoContact = loadable(() => import('../../templates/homepage/ro-contact'));

const MissingTranslation = ({ data }) => {
  return (
    <SiteLayout relatedPages={data?.mdx?.fields?.relatedPages} title={data?.mdx?.frontmatter?.title}>
      <div className={"w-full flex flex-col justify-center mb-20"}>
        <h1 className={'text-center mt-5 text-primary-default text-3xl font-semibold'}>{data?.mdx?.frontmatter?.title}</h1>
        <img loading="lazy" className={"w-full md:w-2/3 self-center"} src={data?.mdx?.frontmatter?.image?.publicURL} />
        <div onClick={goBack} className={"self-center"}>
          <div className={"mt-10 uppercase text-primary-default cursor-pointer border-2 rounded-2xl w-72 text-center border-primary-default px-5 py-2 hover:bg-primary-default hover:text-white font-bold"}>
            {data?.mdx?.frontmatter?.buttonText}
          </div>
        </div>
      </div>
      <RoContact />
    </SiteLayout>
  );
};

export default MissingTranslation;
